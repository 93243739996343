<template>
    <el-container>
        <el-aside width="15%">
            <edit-tree treeTitle="部门" :treeData="treeData" @handleNodeClick="handleNodeClick">
            </edit-tree>
        </el-aside>
        <grid-table ref="gridTable" :tableConfig="tableConfig" @selectionChange="handleSelectionChange"
            :buttonConfig="buttonConfig" @handleButtonClick="callMethod" @editRow="handleShowDialog">
        </grid-table>
        <edit-dialog ref="editDialog" v-if="dialogVisible" :formConfig="editFormConfig"
            @handleQuery="handleQuery"></edit-dialog>
        <data-auth-list ref="dataAuthList" v-if="dataAuthListDialogVisible" @handleQuery="handleQuery"></data-auth-list>
        <employee-auth ref="employeeAuth" v-if="employeeAuthDialogVisible" @handleQuery="handleQuery"></employee-auth>
    </el-container>
</template>

<script>
import { submit } from "@/api/base";
import DataAuthList from "@/views/org/employee/components/DataAuthList.vue";
import EmployeeAuth from "@/views/org/employee/components/EmployeeAuth.vue";
export default {
    name: "pageList",
    components: {
        DataAuthList, EmployeeAuth
    },
    data() {
        return {
            buttonConfig: [...(this.$route.meta.pageConfig.buttons || [])],
            tableConfig: { ...(this.$route.meta.pageConfig.table || {}) },
            treeData: [],
            selectRows: [],
            dialogVisible: false,
            dataAuthListDialogVisible: false,
            employeeAuthDialogVisible: false,
            editFormConfig: {},
        };
    },
    mounted() {
        this.$nextTick(async () => {
            const { data } = await submit("/api/department/getTree");
            this.treeData = data;
        });
        this.handleQuery();
    },
    methods: {
        handleNodeClick(data) {
            this.$refs.gridTable.searchParam["DeptCode"] = data.value;
            this.$refs.gridTable.fetchData();
        },
        handleQuery() {
            this.$refs.gridTable.fetchData();
        },
        handleSelectionChange(selection) {
            this.selectRows = selection;
        },
        callMethod(button) {
            let methods = this.$options.methods;
            const _this = this;
            methods[button.method](_this, button);
        },
        handleSearch(_this, button) {
            _this.handleQuery();
        },
        handleAuthData(_this, button) {
            if (
                button.isNeedSelectRow &&
                (!_this.selectRows || _this.selectRows.length == 0)
            ) {
                _this.$baseMessage("请选择数据", "error");
                return false;
            }
            if (
                button.isNeedSelectRow &&
                !button.isMultiSelectRow &&
                _this.selectRows.length > 1
            ) {
                _this.$baseMessage("只能选择一行", "error");
                return false;
            }
            _this.dataAuthListDialogVisible = true;
            _this.$nextTick(() => {
                _this.editFormConfig = { ...button.bindForm };
                _this.$refs.dataAuthList.init(
                    button.label,
                    _this.getTableParam(
                        _this.selectRows,
                        button.isNeedRowKey,
                        button.isMultiSelectRow,
                        _this.tableConfig.rowKey,
                        button.paramJson
                    )
                );
            });
        },
        handleEmployeeAuth(_this, button) {
            if (
                button.isNeedSelectRow &&
                (!_this.selectRows || _this.selectRows.length == 0)
            ) {
                _this.$baseMessage("请选择数据", "error");
                return false;
            }
            if (
                button.isNeedSelectRow &&
                !button.isMultiSelectRow &&
                _this.selectRows.length > 1
            ) {
                _this.$baseMessage("只能选择一行", "error");
                return false;
            }
            _this.employeeAuthDialogVisible = true;
            _this.$nextTick(() => {
                _this.editFormConfig = { ...button.bindForm };
                _this.$refs.employeeAuth.init(
                    button.label,
                    _this.getTableParam(
                        _this.selectRows,
                        button.isNeedRowKey,
                        button.isMultiSelectRow,
                        _this.tableConfig.rowKey,
                        button.paramJson
                    )
                );
            });
        },
        handleShowDialog(_this, button) {
            if (
                button.isNeedSelectRow &&
                (!_this.selectRows || _this.selectRows.length == 0)
            ) {
                _this.$baseMessage("请选择数据", "error");
                return false;
            }
            if (
                button.isNeedSelectRow &&
                !button.isMultiSelectRow &&
                _this.selectRows.length > 1
            ) {
                _this.$baseMessage("只能选择一行", "error");
                return false;
            }
            _this.dialogVisible = true;
            _this.$nextTick(() => {
                _this.editFormConfig = { ...button.bindForm };
                _this.$refs.editDialog.init(
                    button.label,
                    _this.getTableParam(
                        _this.selectRows,
                        button.isNeedRowKey,
                        button.isMultiSelectRow,
                        _this.tableConfig.rowKey,
                        button.paramJson
                    )
                );
            });
        },
        handleToExcute(_this, button) {
            if (
                button.isNeedSelectRow &&
                (!_this.selectRows || _this.selectRows.length == 0)
            ) {
                _this.$baseMessage(`请选择要${button.label}的数据`, "error");
                return false;
            }
            if (
                button.isNeedSelectRow &&
                !button.isMultiSelectRow &&
                _this.selectRows.length > 1
            ) {
                _this.$baseMessage("只能选择一行", "error");
                return false;
            }
            if (button.isNeedConfirm) {
                _this.$baseConfirm(`确定选中项要${button.label}吗？`, null, () => {
                    this.handleExecute(_this, button);
                });
            } else {
                this.handleExecute(_this, button);
            }
        },
        async handleExecute(_this, button) {
            const { msg } = await submit(
                button.url,
                _this.getTableParam(
                    _this.selectRows,
                    button.isNeedRowKey,
                    button.isMultiSelectRow,
                    _this.tableConfig.rowKey,
                    button.paramJson
                )
            );
            if (msg) {
                _this.$baseMessageBox(
                    `${button.label}成功，${msg}`,
                    `${button.label}提示`
                );
            } else {
                _this.$baseMessage(`${button.label}成功`, "success");
            }
            _this.handleQuery();
        },
    },
};
</script>
