<template>
    <el-dialog title="权限设置" :visible="visible" :close-on-click-modal="false" width="1100px" @close="close">
        <el-tree ref="tree" :data="treeData" node-key="value" :show-checkbox="true">
            <span slot-scope="{ node, data }">
                <i v-if="data.Children" class="el-icon-folder" />
                <i v-else class="el-icon-document" />
                {{ node.label }}
            </span>
        </el-tree>
        <div slot="footer" class="dialog-footer">
            <span class="checkbox-block">
                <el-checkbox v-model="isSelectAll" @change="selectAll">
                    全选
                </el-checkbox>
                <el-checkbox v-model="isInherit" @change="inherit"> 继承 </el-checkbox>
            </span>
            <el-button type="primary" @click="save">提交</el-button>
            <el-button @click="close">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { submit } from "@/api/base";

export default {
    name: "EmployeeAuth",
    data() {
        return {
            visible: false,
            param: {},
            treeData: [],
            isSelectAll: false,
            isInherit: false,
            dutyAuth: [],
        };
    },
    methods: {
        init(type, param) {
            this.visible = true;
            this.param = param;
            this.$nextTick(() => {
                this.fetchData();
            });
        },
        async fetchData() {
            const { data } = await submit(
                "/api/employee/getEmployeeAuthInfo",
                this.param
            );
            this.treeData = data.AllAuthList;
            this.isInherit = data.IsInherit;
            this.dutyAuth = data.DutyAuthList || [];
            this.$nextTick(() => {
                let authedList = data.AuthList || [];
                if (this.isInherit && this.dutyAuth.length > 0) {
                    this.$refs.tree.setCheckedKeys(this.getCheckedKeys(this.dutyAuth));
                    this.$refs.tree.getCheckedNodes().forEach((item) => {
                        item.disabled = true;
                    });
                    this.$refs.tree.setCheckedKeys([]);
                    authedList = [...authedList, ...this.dutyAuth];
                }
                this.$refs.tree.setCheckedKeys(this.getCheckedKeys(authedList));
            });
        },
        getCheckedKeys(authedList) {
            const checkedKeys = [];
            (authedList || []).forEach((key) => {
                const node = this.$refs.tree.getNode(key);
                if (node && node.isLeaf) {
                    checkedKeys.push(key);
                }
            });
            return checkedKeys;
        },
        selectAll() {
            if (this.isInherit) {
                return;
            }
            this.$refs.tree.setCheckedNodes(this.isSelectAll ? this.treeData : []);
        },
        inherit() {
            let oldKeys = [];
            if (this.isInherit) {
                oldKeys = this.$refs.tree.getCheckedKeys();
                this.$refs.tree.setCheckedKeys(this.getCheckedKeys(this.dutyAuth));
                this.$refs.tree.getCheckedNodes().forEach((item) => {
                    item.disabled = true;
                });
                this.$refs.tree.setCheckedKeys(this.getCheckedKeys([...this.dutyAuth, ...oldKeys]));
            } else {
                this.$refs.tree.getCheckedNodes().forEach((item) => {
                    item.disabled = false;
                    if (!this.dutyAuth.includes(item.value)) {
                        oldKeys.push(item.value);
                    }
                });
                this.$refs.tree.setCheckedKeys(this.getCheckedKeys(oldKeys));
            }
        },
        async save() {
            let param = {};
            let checkKeys = this.$refs.tree.getCheckedKeys();
            let halfCheckKeys = this.$refs.tree.getHalfCheckedKeys();
            param.ids = [...checkKeys, ...halfCheckKeys];
            param.EmpCode = this.param.EmpCode;
            param.IsInherit = this.isInherit;
            await submit("/api/employee/addEmployeeAuth", param);
            this.$baseMessage("保存成功", "success");
            this.$emit("handleQuery");
            this.close();
        },
        close() {
            this.treeData = [];
            this.visible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
    position: relative;

    .checkbox-block {
        position: absolute;
        left: 28px;
        top: 8px;
    }
}
</style>