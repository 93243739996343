<template>
  <el-dialog :title="title" :visible="visible" :close-on-click-modal="false" width="620px" @close="close">
    <div class="operation">
      <el-button type="primary" @click="addAuth">新增</el-button>
      <el-button type="primary" @click="deleteAuth">删除</el-button>
    </div>
    <div>
      <grid-table ref="gridTable" style="height: 300px;" :tableConfig="tableConfig" :showPagination="false" @selectionChange="selectionChange">
      </grid-table>
    </div>
    <div class="page-dialog">
      <data-auth-edit ref="dataAuthEdit" v-if="dataAuthEditDialogVisible" @handleQuery="fetchData"></data-auth-edit>
    </div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
import DataAuthEdit from "@/views/org/employee/components/DataAuthEdit.vue";

export default {
  name: "DataAuthList",
  components: {
    DataAuthEdit,
  },
  data() {
    return {
      visible: false,
      type: "",
      title: "",
      form: {},
      rules: {},
      tableConfig: {
        url: "/api/employee/getDataAuthList",
        columns: [
          {
            label: "权限类型",
            prop: "AuthTypeStr",
            type: "text",
            width: "150px",
          },
          {
            label: "权限值",
            prop: "AuthDataName",
            type: "text",
            width: "250px",
          },
        ],
      },
      selectRows: [],
      dataAuthEditDialogVisible: false,
    };
  },
  methods: {
    init(type, param) {
      this.type = "数据权限";
      this.title = "数据权限";
      this.form = param;
      this.$nextTick(() => {
        this.fetchData();
      });
      this.visible = true;
    },
    async fetchData() {
      const { data } = await submit(this.tableConfig.url, this.form);
      this.$refs.gridTable.tableData = data;
    },
    selectionChange(selection) {
      this.selectRows = selection;
    },
    addAuth() {
      this.dataAuthEditDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.dataAuthEdit.init(this.form);
      });
    },
    deleteAuth() {
      if (this.selectRows.length == 0) {
        this.$baseMessage("请选择行", "error");
        return;
      }
      this.$baseConfirm("确定选中行要删除吗？", null, async () => {
        let param = { ...this.form };
        param["AuthIDs"] = this.selectRows.map((row) => row.AuthID).join();
        await submit("/api/employee/deleteDataAuth", param);
        this.$baseMessage("删除成功", "success");
        this.fetchData();
      });
    },
    close() {
      this.$refs.gridTable.tableData = [];
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.editForm {
  border: 1px solid #ebeef5;
  padding-top: 10px;
  margin-bottom: 10px;
}

.operation {
  border: 1px solid #ebeef5;
  padding: 5px;
  margin-bottom: 10px;

  >div {
    display: inline-block;
    margin: 0 10px;
  }
}
</style>
