<template>
  <el-dialog title="新增数据权限" :visible="visible" :close-on-click-modal="false" width="1100px" @close="close" append-to-body>
    <el-form ref="editForm" :model="editForm.data" :rules="editForm.rules" label-position="right" label-width="120px"
      :inline-message="true">
      <template v-for="(item, index) in editForm.items">
        <edit-form-item v-if="index == 0 || editForm.data.AuthType == item.authType" :key="index" :form="editForm.data"
          :item="item" labelWidth="70px" tipWidth="100px"></edit-form-item>
      </template>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">提交</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";

export default {
  name: "DataAuthEdit",
  components: {},
  data() {
    return {
      visible: false,
      editForm: {
        url: "/api/employee/getDataAuthInfo",
        saveUrl: "/api/employee/addDataAuth",
        items: [
          {
            type: "select",
            label: "权限类型",
            prop: "AuthType",
            dataType: "number",
            width: "420px",
            isRequired: true,
            optionJson:
              '[{"label":"仓库","value":0},{"label":"品牌","value":1},{"label":"店铺","value":2},{"label":"采购商","value":3},{"label":"区域管理","value":4},{"label":"成本价管理","value":5}]',
          },
          {
            authType: 0,
            type: "select",
            label: "仓库权限",
            prop: "WID",
            dataType: "number",
            width: "420px",
            isRequired: true,
            optionField: "WarehouseList",
          },
          {
            authType: 1,
            type: "select",
            label: "品牌",
            prop: "BrandID",
            dataType: "number",
            width: "420px",
            isRequired: true,
            optionField: "BrandList",
          },
          {
            authType: 2,
            type: "select",
            label: "店铺权限",
            prop: "AInfoID",
            dataType: "number",
            width: "420px",
            isRequired: true,
            optionField: "ShopList",
          },
          {
            authType: 3,
            type: "select",
            label: "采购商权限",
            prop: "DistributorsID",
            dataType: "number",
            width: "420px",
            isRequired: true,
            optionField: "DistributorsList",
          },
          {
            authType: 4,
            type: "select",
            label: "区域权限",
            prop: "RegionalID",
            dataType: "number",
            width: "420px",
            isRequired: true,
            optionField: "RegionalList",
          },
          {
            authType: 5,
            type: "select",
            label: "成本价权限",
            prop: "IsShowCostPrice",
            dataType: "number",
            width: "420px",
            isRequired: true,
            optionJson:
              '[{"label":"隐藏","value":0},{"label":"显示","value":1}]',
          },
        ],
        data: {
          UserID: "",
          AuthType: 0,
          WID: null,
          BrandID: null,
          AInfoID: null,
          DistributorsID: null,
          RegionalID: null,
          IsShowCostPrice: 0,
        },
      },
    };
  },
  methods: {
    init(param) {
      this.visible = true;
      this.editForm.data.UserID = param.EmpCode;
      this.$nextTick(() => {
        this.fetchData(param);
      });
    },
    async fetchData() {
      const { data } = await submit(this.editForm.url);
      const { items, rules } = this.getFormRules(
        this.editForm.items,
        data.InitData
      );
      this.$set(this.editForm, "items", items);
      this.$set(this.editForm, "rules", rules);
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    save() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          let authValue = {
            0: this.editForm.data.WID,
            1: this.editForm.data.BrandID,
            2: this.editForm.data.AInfoID,
            3: this.editForm.data.DistributorsID,
            4: this.editForm.data.RegionalID,
            5: this.editForm.data.IsShowCostPrice,
          };
          let param = {
            UserID: this.editForm.data.UserID,
            AuthType: this.editForm.data.AuthType,
            AuthValue: authValue[this.editForm.data.AuthType],
          };
          await submit(this.editForm.saveUrl, param);
          this.$baseMessage("保存成功", "success");
          this.$emit("handleQuery");
          this.close();
        } else {
          return false;
        }
      });
    },
    close() {
      this.$refs.editForm.resetFields();
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.editForm {
  border: 1px solid #ebeef5;
  padding-top: 10px;
  margin-bottom: 10px;
}

.operation {
  border: 1px solid #ebeef5;
  padding: 5px;
  margin-bottom: 10px;

  >div {
    display: inline-block;
    margin: 0 10px;
  }
}
</style>
